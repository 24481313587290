import React from 'react';
import PropTypes from 'prop-types';

function SubNavigation({ children }) {
  return (
    <nav className="sub-nav">
      <ul>
        {children.map((link) => (
          <li className="sub-nav__item" key={link.key || link.props.key}>
            {link}
          </li>
        ))}
      </ul>
    </nav>
  );
}

SubNavigation.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SubNavigation;
