import React from 'react';
import PropTypes from 'prop-types';

// Utils
import sentenceCase from 'Utils/content/sentence-case';
import { getReadableNumber } from 'Webapp/utils/numbers-formatter';

// Components
import SubNavigation from 'ComponentLibrary/navigation/sub-navigation';
import WebLink from './web-link';
import withT from 'ComponentLibrary/hocs/withT';

const getIfElseList = (predicate, a, b) => (predicate ? a : b);
const getIfElseListIncludes =
  (a = [], b = []) =>
  (isOwnProfile, key) =>
    getIfElseList(isOwnProfile, a, b).includes(key);

const isMetricAnchor = getIfElseListIncludes(
  [
    'community-groups',
    'followers',
    'group-magazines',
    'magazines',
    // 'personalized-topics',
    'smart-magazines',
    'storyboards',
  ],
  ['followers', 'flips', 'likes', 'magazines', 'storyboards', 'videos'],
);

const isMetricPrefixed = getIfElseListIncludes(['flips', 'likes', 'videos']);

const isMetricExcluded = getIfElseListIncludes([], ['following']);

const getLinkUrl = function (section, isOwnProfile, key) {
  if (!isOwnProfile) {
    return section.canonicalPath;
  }
  const isPrefixed = isMetricPrefixed(isOwnProfile, key);
  const isAnchor = isMetricAnchor(isOwnProfile, key);

  const prefix = isPrefixed ? '/profile' : '';
  return isAnchor ? '/profile' : `${prefix}/${key}`;
};

const STAT_TRANSLATION_KEYS = {
  flips: 'flip',
  likes: 'like',
  magazines: 'magazine',
  storyboards: 'storyboard',
  'group-magazines': 'group_magazine',
  // 'personalized-topics': 'personalized_topic', //TODO: remove the next when rolling out personalized topics
  'smart-magazines': 'smart_magazine',
  'community-groups': 'community_group',
  followers: 'follower',
  following: 'following',
  videos: 'video',
};
const getStatLabel = (key, count = 0, t) => {
  const statLabel = STAT_TRANSLATION_KEYS[key];
  // Flap returns metrics greater than 1000 in the string format: 1,xxx
  // This causes parseInt to only parse up to the non numeric character
  // eg. parseInt('1,234) is 1
  const numericCount =
    count && typeof count === 'string'
      ? parseInt(count.replace(/[^\d]/g, ''), 10)
      : count;

  return t(statLabel, { count: numericCount });
};

const isMetricEmpty = (value) => Number(value) === 0;

// these are keys that come from flap, we only want to translate these
// once and then use the values here for everything else
const NORMALIZED_METRIC_NAME_MAP = {
  articles: 'flips',
  communityGroups: 'community-groups',
  favorite: 'likes',
  flips: 'flips',
  follow: 'following',
  follower: 'followers',
  fediverseFollowers: 'fediverse-followers',
  groupMagazines: 'group-magazines',
  likes: 'likes',
  magazineCount: 'magazines',
  packageCount: 'storyboards',
  smartMagazines: 'smart-magazines',
  videos: 'videos',
};
const normalizeMetrics = (metrics, isOwnProfile) =>
  Object.keys(metrics).reduce((acc, key) => {
    const normalizedKey = NORMALIZED_METRIC_NAME_MAP[key];
    if (
      isMetricEmpty(metrics[key]) ||
      isMetricExcluded(isOwnProfile, normalizedKey)
    ) {
      return acc;
    }
    acc[normalizedKey] = metrics[key];
    return acc;
  }, {});

const decorateMetrics = (metrics) => {
  // fairly gross, to satisfy https://flipboard.atlassian.net/browse/FL-35767
  if (metrics.fediverseFollowers) {
    metrics.follower = (metrics.follower || 0) + metrics.fediverseFollowers;
    delete metrics.fediverseFollowers;
  }
  return metrics;
};

const getMetricsProperties = (section, metrics, isOwnProfile, t) => {
  const normalizedMetrics = normalizeMetrics(
    decorateMetrics(metrics),
    isOwnProfile,
  );
  return Object.keys(normalizedMetrics).map((key) => {
    const isAnchor = isMetricAnchor(isOwnProfile, key);
    const value = getReadableNumber(normalizedMetrics[key]);
    return {
      key,
      hash: isAnchor ? key : undefined,
      href: getLinkUrl(section, isOwnProfile, key),
      includeActiveClassname: !isAnchor,
      label: sentenceCase(getStatLabel(key, normalizedMetrics[key], t)),
      value,
    };
  });
};

function ProfileMetricsSubNav({ section, metrics, isOwnProfile, t }) {
  if (!metrics) {
    return null;
  }

  return (
    <SubNavigation>
      {getMetricsProperties(section, metrics, isOwnProfile, t).map(
        ({ key, hash, href, includeActiveClassname, label, value }) => (
          <WebLink
            hash={hash}
            href={href}
            className="sub-nav__item-link"
            activeClassName={
              includeActiveClassname ? 'sub-nav__item-link--selected' : null
            }
            key={key}
          >
            <React.Fragment>
              {value.toLocaleString()}
              &nbsp;
              <span>{label}</span>
            </React.Fragment>
          </WebLink>
        ),
      )}
    </SubNavigation>
  );
}

ProfileMetricsSubNav.propTypes = {
  section: PropTypes.object.isRequired,
  metrics: PropTypes.object,
  isOwnProfile: PropTypes.bool,
  t: PropTypes.func.isRequired,
};

ProfileMetricsSubNav.defaultProps = {
  metrics: null,
  isOwnProfile: false,
};

export default withT(ProfileMetricsSubNav);
